const timeConverter = function (seconds) {
	if (isNaN(seconds)) {
		return 'Closed'
	}

	let hour = Math.floor(seconds / 3600)
	seconds -= hour * 3600
	if (hour >= 24) {
		hour -= 24
	}
	let min = Math.floor(seconds / 60)
	// var sec = a.getSeconds();

	if (min < 10) {
		min = '0' + min
	}

	let time = ''
	if (hour == 0) {
		time = '12' + ':'
	} else if (hour > 12) {
		time = (hour - 12) + ':'
	} else {
		time = hour + ':'
	}

	if (min < 10 && min != '00') {
		time += '0' + min
	} else {
		time += min
	}

	if (hour >= 12) {
		time += ' pm'
	} else {
		time += ' am'
	}

	return time
}

export default timeConverter
