var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-start text-center border-b font-bold text-sm my-2"},[(_vm.hasDelivery)?_c('div',{staticClass:"flex items-center justify-center py-2",class:[
			_vm.value === _vm.DELIVERY ? _vm.activeClass : 'opacity-50',
			'cursor-pointer'
		],on:{"click":function($event){return _vm.setDispatch(_vm.DELIVERY)}}},[_c('img',{attrs:{"src":require("@/assets/icons/delivery.svg"),"alt":"Delivery","width":"25","height":"25"}}),_vm._v(" "),_c('span',{staticClass:"p-1"},[_vm._v("\n\t\t\tDelivery\n\t\t")])]):_vm._e(),_vm._v(" "),(_vm.hasPickup)?_c('div',{staticClass:"flex items-center justify-center py-2 px-4",class:[
			_vm.value === _vm.PICKUP ? _vm.activeClass : 'opacity-50',
			'cursor-pointer'
		],on:{"click":function($event){return _vm.setDispatch(_vm.PICKUP)}}},[_c('img',{attrs:{"src":require("@/assets/icons/shop.svg"),"alt":"Shop","width":"20","height":"20"}}),_vm._v(" "),_c('span',{staticClass:"p-1"},[_vm._v("\n\t\t\tPick-Up\n\t\t")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }