<template>
	<div
		v-if="businessId"
		class="pb-4"
	>
		<div
			v-if="showOrderForm"
			class="flex flex-wrap"
		>
			<OrderForm
				class="w-full"
				:cart="cart"
				:claimed-deals="claimedDeals"
				:claimed-deals-ids="claimedDealsIds"
				:has-claimed-deals="hasClaimedDeals"
			/>
		</div>

		<template v-else>
			<EmptyList
				:image-src="emptyCartIcon"
				copy="You have no items in your cart."
				class="pt-10"
			/>
			<div class="flex justify-center w-full pb-4">
				<router-link
					:to="{ name: cartUrl, params: $route.params }"
					class="block font-bold text-center text-green-500 hover:text-purple-500"
				>
					Continue Shopping
				</router-link>
			</div>
		</template>
		<div
			v-if="hasDeals"
			class="max-w-full"
		>
			<h3 class="py-2 text-xl font-bold">
				Deals Available
			</h3>
			<div class="flex pb-8 space-x-2 overflow-x-auto overflow-y-hidden">
				<DealCard
					v-for="(deal, index) in mappedDeals"
					:key="`${deal.id}-${index}`"
					:deal="deal"
					:class="[
						'relative',
						{ claimed: isClaimed(deal) }
					]"
					:prevent-route="true"
					:is-claimed="isClaimed(deal)"
					@click.native.prevent="toggleClaim(deal)"
				/>
			</div>
		</div>
	</div>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import destructureDeal from '@/api/helpers/destructureDeal.js'
import DealCard from '@/components/deal/DealCard.vue'
import EmptyList from '@/components/multiUse/EmptyList.vue'
import OrderForm from '@/components/order/OrderForm.vue'
import { useDealClaim } from '@/composables/dealClaim.js'

const { claimedDeals } = useDealClaim()

export default {
	components: {
		EmptyList,
		OrderForm,
		DealCard
	},
	data () {
		return {
			claimedDealsIds: claimedDeals.value,
			showDeals: false,
			cartUrl: 'businessIndex',
			emptyCartIcon: require('@/assets/icons/empty-cart-icon.png'),
			isMounted: false
		}
	},
	computed: {
		...mapGetters('cart', [
			'cart'
		]),
		...mapGetters('business', [
			'businessId',
			'deals'
		]),
		mappedDeals() {
			return this.deals?.map(deal => { return destructureDeal(deal) })
		},
		numberOfItems() {
			return this.cart.num_items
		},
		cartHasItems() {
			return this.numberOfItems > 0
		},
		hasDeals() {
			return !!this.mappedDeals?.length && !!this.cartHasItems
		},
		hasClaimedDeals() {
			return !!this.claimedDealsIds?.length && this.hasDeals
		},
		claimedDeals() {
			if (!this.hasDeals || !this.hasClaimedDeals) return
			return this.mappedDeals.filter(deal => {
				return this.claimedDealsIds.indexOf(deal.id) > -1
			})
		},
		showOrderForm() {
			return this.cartHasItems && this.isMounted
		}
	},
	mounted() {
		this.closeSlideOver()
		this.isMounted = true
	},
	methods: {
		...mapMutations('slideOver', [ 'showSlideOver', 'closeSlideOver' ]),
		toggleClaim(deal) {
			const dealId = deal.id

			if (this.claimedDealsIds.indexOf(dealId) > -1) {
				this.claimedDealsIds = this.claimedDealsIds.filter(claimedDeal => {
					return claimedDeal !== dealId
				})
			} else {
				this.claimedDealsIds.push(dealId)
			}
			return false
		},
		isClaimed(deal) {
			const dealId = deal.id
			if (this.claimedDealsIds.indexOf(dealId) > -1) return true
			return false
		}
	}
}
</script>
