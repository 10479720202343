<template>
	<div class="border border-gray-300 rounded md:min-w-88 xl:min-w-96">
		<div class="flex flex-wrap">
			<h2 class="w-full px-3 py-2 my-3 text-xl font-bold border-b">
				Summary
			</h2>
			<div class="w-full md:flex-grow">
				<CartProductCardList
					:cart="cart"
					:is-checkout="true"
					:listing-id="listingId"
				/>
				<div
					v-if="hasClaimedDeals"
					class="p-2 px-3"
				>
					<div class="pb-2">
						<span class="font-bold">
							Deals:
						</span> {{ business.name }} will verify that you have met the requirements for these deals.
					</div>
					<ClaimedDealCard
						v-for="(claimedDeal, index) in claimedDeals"
						:key="index"
						:deal="claimedDeal"
					/>
				</div>
			</div>
			<div class="flex flex-wrap w-full px-3">
				<div class="w-1/2 py-1 text-left uppercase">
					Subtotal ({{ cart.num_items }})
				</div>
				<div class="w-1/2 py-1 text-right">
					${{ subtotal }}
				</div>
				<template v-if="hasTax">
					<div class="w-1/2 text-left">
						Tax
					</div>
					<div class="w-1/2 text-right">
						${{ cart.tax.toFixed(2) }}
					</div>
				</template>
				<template v-if="deliveryFee || hasDeliveryOptions">
					<div class="flex items-center justify-between w-full">
						<div class="py-1 text-left uppercase flex-nowrap">
							Delivery Area
						</div>
						<div
							v-if="hasDeliveryOptions"
							class="w-auto px-3 py-2 text-left"
						>
							<select
								class="w-full py-1 capitalize bg-white border border-gray-300 rounded shadow-sm cursor-pointer hover:shadow focus:ring focus:ring-green-300 focus:border-gray-300"
								required
								:value="value"
								@change="updateDeliveryOption($event.target.value)"
							>
								<option
									v-for="deliveryOption in deliveryOptions"
									:key="deliveryOption.title"
									:value="deliveryOption.id"
								>
									{{ deliveryOption.title }}
								</option>
							</select>
						</div>
						<div class="text-right">
							${{ deliveryFee.toFixed(2) }}
						</div>
					</div>
				</template>
			</div>
		</div>
		<div class="flex w-full px-4 py-3 font-bold bg-gray-200 border-t border-gray-300 font-lg">
			<div class="w-1/2 text-left uppercase">
				Total
			</div>
			<div class="w-1/2 text-right">
				${{ total.toFixed(2) }}
			</div>
		</div>
	</div>
</template>

<script async>
import CartProductCardList from '@/components/cart/CartProductCardList.vue'
import ClaimedDealCard from '@/components/deal/ClaimedDealCard.vue'

export default {
	components: {
		CartProductCardList,
		ClaimedDealCard
	},
	props: {
		cart: {
			type: Object,
			required: true
		},
		value: {
			type: Number,
			required: true
		},
		hasDelivery: {
			type: Boolean,
			default: false
		},
		hasClaimedDeals: {
			type: Boolean,
			default: false
		},
		claimedDeals: {
			type: Array,
			default: () => []
		},
		business: {
			type: Object,
			required: true
		}
	},
	emits: [ 'input' ],
	computed: {
		hasTax() {
			return this.cart && this.cart.tax
		},
		hasDeliveryOptions() {
			return this.hasDelivery
		},
		deliveryOptions() {
			return this.hasDeliveryOptions && this.cart?.settings?.delivery?.prices
		},
		selectedDeliveryOption() {
			if (this.deliveryOptions.length === 1) {
				this.updateDeliveryOption(this.deliveryOptions[0].id)
				return this.deliveryOptions[0]
			}
			if (this.deliveryOptions) {
				return this.deliveryOptions.filter(
					(option) => option.id === this.value
				)[0]
			}
			return false
		},
		deliveryFee() {
			return Math.round(this.selectedDeliveryOption.price * 100) / 100 || 0
		},
		total() {
			return this.cart.subtotal + this.cart.tax + this.deliveryFee
		},
		subtotal() {
			const { subtotal } = this.cart
			return Number(subtotal) ? subtotal.toFixed(2) : 0
		},
		listingId() {
			return String(this.business.listing_id)
		}
	},
	methods: {
		updateDeliveryOption(deliveryOption) {
			this.$emit('input', Number(deliveryOption))
		}
	}
}
</script>
