<template>
	<label
		class="flex items-center justify-between cursor-pointer"
		:for="id"
	>
		<input
			:id="id"
			:disabled="disabled"
			:checked="checked"
			:required="required"
			type="checkbox"
			class="absolute opacity-0"
			@change="$emit('checked')"
		>
		<div
			class="relative z-10 w-12 h-6 px-1 text-xs rounded-full fake-switch"
			:class="!!$slots.default ? 'mr-2' : ''"
		>
			<div class="absolute bg-white mt-0.5 h-4 w-4 rounded-full fake-switch-inner shadow-md" />
		</div>
		<slot name="default" />
	</label>
</template>

<script async>

export default {
	props: {
		checked: {
			type: Boolean,
			deafult: false
		},
		id: {
			type: String,
			required: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		}
	},
	emits: [ 'checked' ]
}
</script>

<style scoped>
.fake-switch {
	/* border-gray-200 */
	border-color: #f1f5f8;

	/* bg-gray-200 */
	background-color: #f1f5f8;

	/* border-2 */
	border-width: 2px;

	/* border-2 border-gray-200 bg-gray-200; */
	transition: all 0.3s;
}
.fake-switch-inner {
	transition: transform 0.3s;
}
input:checked ~ .fake-switch {
	/* border-green-500; */
	border-color: #1ec36a;

	/* bg-green-500 */
	background-color: #1ec36a;
}
input:checked ~ .fake-switch > .fake-switch-inner {
	transform: translate(20px, 0);
}
</style>
