<template>
	<div class="flex justify-start text-center border-b font-bold text-sm my-2">
		<div
			v-if="hasDelivery"
			class="flex items-center justify-center py-2"
			:class="[
				value === DELIVERY ? activeClass : 'opacity-50',
				'cursor-pointer'
			]"
			@click="setDispatch(DELIVERY)"
		>
			<img
				src="@/assets/icons/delivery.svg"
				alt="Delivery"
				width="25"
				height="25"
			> <span class="p-1">
				Delivery
			</span>
		</div>
		<div
			v-if="hasPickup"
			class="flex items-center justify-center py-2 px-4"
			:class="[
				value === PICKUP ? activeClass : 'opacity-50',
				'cursor-pointer'
			]"
			@click="setDispatch(PICKUP)"
		>
			<img
				src="@/assets/icons/shop.svg"
				alt="Shop"
				width="20"
				height="20"
			>
			<span class="p-1">
				Pick-Up
			</span>
		</div>
	</div>
</template>

<script async>
import { PICKUP_OR_DELIVERY } from '@/constants/business/index.js'

export default {
	props: {
		value: {
			type: String,
			required: true
		},
		hasPickup: {
			type: Boolean,
			default: false
		},
		hasDelivery: {
			type: Boolean,
			default: false
		}
	},
	emits: [ 'input' ],
	data() {
		return {
			activeClass: 'border-b-4 border-green-500',
			DELIVERY: PICKUP_OR_DELIVERY.DELIVERY,
			PICKUP: PICKUP_OR_DELIVERY.PICKUP
		}
	},
	mounted() {
		this.hasDelivery
			? this.setDispatch(this.DELIVERY)
			: this.setDispatch(this.PICKUP)
	},
	methods: {
		setDispatch(dispatchType) {
			this.$emit('input', dispatchType)
		}
	}
}
</script>
