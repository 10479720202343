<template>
	<div>
		<TransitionGroup
			v-if="hasCartItems"
			name="fade-in"
			mode="out-in"
			tag="ul"
			class="grid justify-center w-full grid-cols-1"
		>
			<CartProductCard
				v-for="(item, index) in cart.items"
				:key="`${item.productId || item.product_id}-${item.size}`"
				:style="{'--index': index}"
				:product="item"
				:is-checkout="isCheckout"
				:listing-id="listingId"
			/>
		</TransitionGroup>
	</div>
</template>

<script async>
import CartProductCard from '@/components/cart/CartProductCard.vue'

export default {
	components: {
		CartProductCard
	},
	props: {
		cart: {
			type: Object,
			default: () => ({})
		},
		isCheckout: {
			type: Boolean,
			default: false
		},
		listingId: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			isMounted: false
		}
	},
	computed: {
		hasCartItems() {
			return !!this.cart?.items.length && this.isMounted
		}
	},
	mounted() {
		this.isMounted = true
	}
}
</script>

<style scoped>
	.fade-in-move,
	.fade-in-enter-active,
	.fade-in-leave-active {
		transition: all .5s ease;
		transition-delay: calc(0.08s * var(--index));
	}
	.fade-in-enter,
	.fade-in-leave-to {
		opacity: 0;
		transform: translateY(50px);
		transition: all 0.2s ease;
	}
</style>
