<template>
	<div
		id="business-cart-checkout"
		class="px-3"
	>
		<Checkout />
	</div>
</template>

<script async>
import Checkout from '@/components/cart/Checkout.vue'

export default {
	components: {
		Checkout
	}
}
</script>
