<template>
	<div v-if="hasNoDispatchMethod">
		This business does not offer pickup or delivery.
	</div>
	<div
		v-else
		class="flex flex-col flex-1 my-4 lg:space-x-1.5 xl:space-x-4 lg:flex-row lg:items-start"
	>
		<form
			v-require-login="{ callback: handleOrder }"
			:data-guests-allowed="cart.settings.guestsAllowed"
			class="w-full lg:w-1/2 lg:mr-1.5 xl:mr-0"
		>
			<div class="border border-gray-300 rounded shadow-sm lg:w-auto">
				<OrderDispatch
					v-model="orderForm.order_type"
					:has-delivery="hasDelivery"
					:has-pickup="hasPickup"
					class="mx-4"
				/>
				<div class="grid grid-cols-1 gap-4 px-4 my-2">
					<div v-if="orderForm.order_type === DELIVERY">
						<h3 class="py-3 text-xl font-bold">
							Delivery Address
						</h3>
						<div class="grid w-full grid-cols-1 gap-4 lg:grid-cols-2 place-content-between">
							<WwTextInput
								id="delivery-address"
								v-model.trim="orderForm.delivery_addr"
								label="Address"
								placeholder="Enter Address"
								input-class="w-full"
								required
								:error-message="ADDRESS"
								:state="deliveryAddressInputState"
							/>
							<WwTextInput
								id="delivery-address2"
								v-model.trim="orderForm.delivery_addr2"
								label="Address II (Optional)"
								placeholder="Enter Address II"
								input-class="w-full"
							/>
							<WwTextInput
								id="zip-code"
								v-model.trim="orderForm.delivery_zip"
								label="Zip Code"
								placeholder="Enter Zip Code"
								:input-type="ZIP"
								input-class="w-full"
								required
								:error-message="ZIP_CODE"
								:state="inputFieldState(orderForm.delivery_zip, ZIP)"
							/>
							<WwTextInput
								id="city"
								v-model.trim="orderForm.delivery_city"
								label="City"
								placeholder="Enter City"
								input-class="w-full"
								required
								:error-message="CITY"
								:state="deliveryCityInputState"
							/>
						</div>
					</div>
					<template v-if="incompleteProfile">
						<WwTextInput
							id="special-instructions"
							v-model.trim="orderForm.order_notes"
							label="Special Instructions (Optional)"
							placeholder="Enter Special Instructions"
							autocomplete="off"
							input-class="w-full h-24"
							:input-type="TEXTAREA"
						/>
						<h3 class="w-full text-xl font-bold">
							Personal Information
						</h3>
						<div class="grid w-full grid-cols-1 gap-4 lg:grid-cols-2 place-content-between">
							<WwTextInput
								id="first-name"
								v-model.trim="orderForm.first_name"
								label="First Name"
								placeholder="Enter First Name"
								input-class="w-full"
								required
								:error-message="FIRST_NAME"
								:state="formFirstNameInputState"
								@blur="handleTemp"
							/>
							<WwTextInput
								id="last-name"
								v-model.trim="orderForm.last_name"
								label="Last Name"
								placeholder="Enter Last Name"
								input-class="w-full"
								required
								:error-message="LAST_NAME"
								:state="formLastNameInputState"
								@blur="handleTemp"
							/>
							<WwTextInput
								id="dob"
								v-model.trim="orderForm.dob"
								label="Birth Date"
								placeholder="MM/DD/YYYY"
								autocomplete="off"
								:input-type="DATE"
								input-class="w-full"
								required
								:error-message="DOB"
								:state="formDobInputState"
								@blur="handleTemp"
							/>
							<WwTextInput
								id="phone"
								v-model.trim="orderForm.phone"
								label="Phone"
								placeholder="Enter Phone Number"
								:input-type="TEL"
								input-class="w-full"
								required
								:error-message="PHONE"
								:state="formPhoneInputState"
								@updated="updatePhone"
								@blur="handleTemp"
							/>
						</div>
					</template>
					<div v-else>
						<WwTextInput
							id="special-instructions"
							v-model.trim="orderForm.order_notes"
							label="Special Instructions (Optional)"
							placeholder="Enter Special Instructions"
							autocomplete="off"
							input-class="w-full h-24"
							:input-type="TEXTAREA"
						/>
						<div class="grid w-full grid-cols-1 py-2 gap-y-2 lg:gap-y-3 gap-x-4 lg:grid-cols-2 place-content-between">
							<h3 class="w-full py-2 text-xl font-bold lg:col-span-2">
								Personal Information
							</h3>
							<div class="w-full text-sm font-semibold uppercase">
								<label class="font-bold">
									First Name
								</label>
								<div class="text-gray-500">
									{{ auth.profile.first_name }}
								</div>
							</div>
							<div class="w-full text-sm font-semibold uppercase">
								<label class="font-bold">
									Last Name
								</label>
								<div class="text-gray-500">
									{{ auth.profile.last_name }}
								</div>
							</div>
							<div class="w-full text-sm font-semibold uppercase">
								<label class="font-bold">
									Phone
								</label>
								<div class="text-gray-500">
									{{ formattedPhone }}
								</div>
							</div>
							<div class="w-full text-sm font-semibold uppercase">
								<label class="font-bold">
									Birth Date
								</label>
								<div class="text-gray-500">
									{{ formattedDob }}
								</div>
							</div>
						</div>
					</div>
					<div
						v-if="isMedcardRequired"
						class="grid w-full grid-cols-1 gap-4 lg:grid-cols-2 place-content-between"
					>
						<WwTextInput
							id="med-id"
							v-model.trim="orderForm.medcard"
							label="Med ID"
							placeholder="Enter Medical ID"
							input-class="w-full"
							required
							:error-message="MED_ID"
							:state="formMedcardInputState"
							@blur="handleTemp"
						/>
						<WwTextInput
							id="med-expiration"
							v-model.trim="orderForm.medcard_expiration"
							label="Med ID - Expiration"
							input-class="w-full"
							:input-type="DATE"
							required
							:error-message="MED_ID_EXP"
							placeholder="MM/DD/YYYY"
							autocomplete="off"
							:state="formMedcardExpInputState"
							@blur="handleTemp"
						/>
					</div>
					<div
						v-if="isLicenseRequired"
						class="grid w-full grid-cols-1 gap-4 lg:grid-cols-2 place-content-between"
					>
						<WwTextInput
							id="dln"
							v-model.trim="orderForm.license_number"
							label="Driver's License Number"
							placeholder="Enter DLN"
							input-class="w-full"
							required
							:error-message="PERSONAL_ID"
							:state="formDlnInputState"
							@blur="handleTemp"
						/>
						<WwTextInput
							id="dln-expiration"
							v-model.trim="orderForm.license_expiration"
							label="Driver's License - Expiration"
							input-class="w-full"
							:input-type="DATE"
							required
							:error-message="PERSONAL_ID_EXP"
							placeholder="MM/DD/YYYY"
							autocomplete="off"
							:state="formDlnExpInputState"
							@blur="handleTemp"
						/>
					</div>

					<OrderTerms
						class="py-3 border-t"
						@input="toggleTerms()"
					/>
					<input
						type="submit"
						:value="sendingOrder ? 'SENDING' : 'Place Order'"
						:disabled="!canOrder || sendingOrder"
						class="w-full p-2.5 my-2 uppercase font-bold text-center border-none rounded text-white"
						:class="(canOrder && !sendingOrder) ? 'bg-green-500 hover:shadow-lg' : 'bg-gray-300 cursor-not-allowed'"
					>
				</div>
			</div>
		</form>
		<OrderSummary
			v-model="orderForm.delivery"
			:cart="cart"
			:dispatch-type="orderForm.order_type"
			:has-delivery="hasDelivery && orderForm.order_type === DELIVERY"
			:has-claimed-deals="hasClaimedDeals"
			:claimed-deals="claimedDeals"
			:business="business"
			class="order-first mb-6 shadow-sm lg:order-last lg:w-1/2"
		/>
	</div>
</template>

<script async>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import destructureOrder from '@/api/helpers/destructureOrder.js'
import OrderDispatch from '@/components/order/helpers/OrderDispatch.vue'
import OrderSummary from '@/components/order/helpers/OrderSummary.vue'
import OrderTerms from '@/components/order/helpers/OrderTerms.vue'
import WwTextInput from '@/components/UI/WwTextInput.vue'
import { PICKUP_OR_DELIVERY } from '@/constants/business/index.js'
import {
	ADDRESS,
	CITY,
	DOB,
	FIRST_NAME,
	LAST_NAME,
	MED_ID,
	MED_ID_EXP,
	PERSONAL_ID,
	PERSONAL_ID_EXP,
	PHONE,
	ZIP_CODE
} from '@/constants/text-inputs/error-messages.js'
import INPUT_STATES from '@/constants/text-inputs/states.js'
import { DATE, NUMBER, TEL, TEXTAREA, ZIP } from '@/constants/text-inputs/types.js'
import { GetCarts } from '@/gql/queries/carts.gql'
import tempModule from '@/store/modules/tempModule.js'
import { dateFormat } from '@/utils/dateFormat.js'
import formatPhone from '@/utils/formatPhone.js'
import { formatUnixDate } from '@/utils/formatUnixTime.js'
import timeConverter from '@/utils/timeConverter.js'
import { inputFieldState } from '@/validators/text-inputs.js'

export default {
	components: {
		WwTextInput,
		OrderSummary,
		OrderDispatch,
		OrderTerms
	},
	props: {
		cart: {
			type: Object,
			default: () => {
				return {
					settings: {}
				}
			}
		},
		claimedDeals: {
			type: Array,
			default: () => []
		},
		claimedDealsIds: {
			type: Array,
			default: () => []
		},
		hasClaimedDeals: {
			type: Boolean,
			default: false
		}
	},
	data: function() {
		const now = new Date()
		const rightNowInSeconds = now.getHours() * 3600 + now.getMinutes() * 60

		return {
			ADDRESS,
			ZIP_CODE,
			CITY,
			FIRST_NAME,
			LAST_NAME,
			PHONE,
			DOB,
			MED_ID,
			MED_ID_EXP,
			PERSONAL_ID,
			PERSONAL_ID_EXP,
			INPUT_STATES,
			TEXTAREA,
			NUMBER,
			DATE,
			TEL,
			ZIP,
			sendingOrder: false,
			orderForm: {
				business: '',
				order_type: '',
				first_name: '',
				last_name: '',
				time_requested: 'asap', // asap or timed
				date_requested: dateFormat(now),
				order_time: '',
				order_notes: '',
				dob: '',
				phone: '',
				delivery_addr: '',
				delivery_addr2: '',
				delivery_city: '',
				delivery_state: '',
				delivery_zip: '',
				delivery: 0,
				medcard: '',
				medcard_expiration: '',
				license_number: '',
				license_expiration: ''
			},
			visible: null,
			collapsed: null,
			agreeToTerms: false,
			now: now,
			rightNowInSeconds: rightNowInSeconds,
			phoneValid: false,
			inputClasses: 'w-full border bg-gray-200 border-gray-300 rounded focus:ring focus:border-gray-300',
			DELIVERY: PICKUP_OR_DELIVERY.DELIVERY,
			PICKUP: PICKUP_OR_DELIVERY.PICKUP,
			cartUrl: 'businessIndex',
			inputFieldState
		}
	},
	computed: {
		...mapGetters('business', [ 'business', 'businessApiParam' ]),
		...mapGetters('auth', [ 'auth' ]),
		dobVerified() {
			return this.dobSeconds < this.minimumDob
		},
		dobSeconds() {
			return new Date(this.orderForm?.dob).getTime() / 1000
		},
		timeNowSeconds() {
			return new Date(this.dateNow).getTime() / 1000
		},
		dateNow() {
			return new Date(Date.now()).toLocaleString().split(',')[0]
		},
		minimumDob() {
			return this.timeNowSeconds - ((31556952 * 21) /* + 86399 */) // seconds in a year * 21 years + seconds in 1 day (minus 1 second). We add 86399 secs so that the day after 21yrs ago isn't counted.
		},
		formFirstNameInputState() {
			return this.orderForm?.first_name?.length ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		formLastNameInputState() {
			return this.orderForm?.last_name?.length ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		formDobInputState() {
			return this.dobVerified ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		formPhoneInputState() {
			if (!this.orderForm?.phone?.length) {
				return INPUT_STATES.ERROR
			}
			return this.phoneValid ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		formMedcardInputState() {
			return this.orderForm?.medcard?.length ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		formMedcardExpInputState() {
			return !this.isMedcardExpired ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		formDlnInputState() {
			return this.orderForm?.license_number?.length ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		formDlnExpInputState() {
			return !this.isLicenseExpired ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		deliveryAddressInputState() {
			return this.orderForm?.delivery_addr?.length ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		deliveryZipInputState() {
			return this.orderForm?.delivery_zip?.length ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		deliveryCityInputState() {
			return this.orderForm?.delivery_city?.length ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		deliveryInfoEntered() {
			if (this.deliveryOptionRequired) {
				if (this.orderForm.delivery_addr !== '' && this.orderForm.delivery_city !== '' && this.orderForm.delivery_zip !== '' && this.orderForm.first_name !== '' && this.orderForm.last_name !== '' && this.orderForm.phone !== '' && this.orderForm.delivery !== null) {
					return true
				}
				return false
			} else {
				if (this.orderForm.delivery_addr !== '' && this.orderForm.delivery_city !== '' && this.orderForm.delivery_zip !== '' && this.orderForm.first_name !== '' && this.orderForm.last_name !== '' && this.orderForm.phone !== '') {
					return true
				}
				return false
			}
		},
		canOrder() {
			if (this.isLicenseRequired && (!this.orderForm?.license_number || !this.orderForm?.license_expiration || this.isLicenseExpired)) return false
			if (this.isMedcardRequired && (!this.orderForm?.medcard || !this.orderForm?.medcard_expiration || this.isMedcardExpired)) return false
			if (this.orderForm.order_type == 'delivery') {
				return Boolean(this.deliveryInfoEntered) && Boolean(this.agreeToTerms) && this.cart.num_items > 0	&& this.phoneValid
			} else {
				return Boolean(this.agreeToTerms) && this.cart.num_items > 0 && this.phoneValid
			}
		},
		hasDelivery() {
			return !!this.cart.settings.delivery.available
		},
		hasPickup() {
			return !!this.cart.settings.pickup
		},
		incompleteProfile () {
			return !this.auth.profile || !this.auth.profile.first_name || !this.auth.profile.last_name || !this.auth.profile.dob || !this.auth.profile.phone
		},
		hasNoDispatchMethod() {
			return !this.cart.settings?.delivery?.available && !this.cart.settings?.pickup
		},
		isSavedMedcardExpired() {
			return Number(this.auth?.profile?.medcard_expiration) < this.timeNowSeconds
		},
		isMedcardRequired() {
			return !!this.cart?.settings?.medcardRequired && (!this.auth.profile.medcard || this.isSavedMedcardExpired)
		},
		isMedcardExpired() {
			if (!this.orderForm?.medcard_expiration?.length) return true
			return this.medcardExiprationSeconds < this.timeNowSeconds
		},
		medcardExiprationSeconds() {
			return new Date(this.medcardExpirationMs) / 1000
		},
		medcardExpirationMs() {
			return new Date(this.orderForm?.medcard_expiration).getTime() || Number(this.auth?.profile?.medcard_expiration * 1000)
		},
		isLicenseRequired() {
			return !!this.cart?.settings?.licenseRequired
		},
		licenseExpiration() {
			return new Date(this.orderForm?.license_expiration).getTime()
		},
		isLicenseExpired() {
			if (!this.orderForm?.license_expiration?.length) return true
			return this.licenseExpiration < this.now
		},
		formattedPhone() {
			return formatPhone(this.auth?.profile?.phone)
		},
		formattedDob() {
			return formatUnixDate(this.auth?.profile?.dob)
		}
	},
	watch: {
		'auth.profile': {
			handler() {
				if (Object.keys(this.auth?.profile).length) {
					this.orderForm.first_name = this.auth.profile.first_name
					this.orderForm.last_name = this.auth.profile.last_name
					this.orderForm.dob = formatUnixDate(parseInt(this.auth?.profile?.dob), true)
					this.orderForm.phone = this.auth.profile.phone
					this.orderForm.license_number = this.auth.profile?.drivers_license
					this.orderForm.medcard = this.auth.profile?.medcard
					this.orderForm.medcard_expiration = this.auth.profile?.medcard_expiration
					if (this.orderForm.phone != '') {
						this.phoneValid = true
					}
				}
			},
			immediate: true
		},
		businessApiParam: {
			handler() {
				this.orderForm.business = this.businessApiParam
			},
			immediate: true
		},
		'cart.settings': {
			handler() {
				if (this.cart.settings.delivery && this.cart.settings.delivery.available) {
					this.orderForm.order_type = (this.cart.settings.delivery.available ? 'delivery' : 'pickup')
					this.orderForm.delivery = this.cart.settings.delivery.prices[0].id
				}
			},
			immediate: true
		}
	},
	beforeCreate() {
		this.$store.registerModule('temp', tempModule)
	},
	methods: {
		...mapMutations('toast', [ 'showToast' ]),
		...mapActions('temp', [ 'setTemp', 'any' ]),
		...mapActions('cart', [ 'placeOrder' ]),
		timeConverter,
		async handleOrder() {
			this.sendingOrder = true
			if (this.claimedDeals.length > 0) {
				this.orderForm.deals = this.claimedDeals
			}
			const orderResponse = await this.placeOrder(this.orderForm)
			const { carts } = this.$apollo.provider.defaultClient.readQuery({
				query: GetCarts
			})
			if (carts?.length > 0) {
				const listingId = orderResponse?.results?.order?.listing_id || ''
				const cartIndex = carts?.findIndex(cart => parseInt(cart.listing?.id) === parseInt(listingId))
				carts.splice(cartIndex, 1)
				this.$apollo.provider.defaultClient.writeQuery({
					query: GetCarts,
					variables: {
						listingId: listingId
					},
					data: {
						carts
					}
				})
			}
			if (orderResponse.success) {
				if (orderResponse.redirect) {
					this.$router.push(orderResponse.redirect)
				}

				if (this.auth.id < 1) {
					this.setTemp({ order: destructureOrder(orderResponse.results.order) })
					this.$router.push({ name: 'guestPreOrder' })
				}
			}
			this.sendingOrder = false
		},
		updatePhone(payload) {
			this.phoneValid = payload.isValid()
		},
		handleTemp() {
			const {
				first_name: firstName, last_name: lastName, phone, dob, delivery_zip: zip
			} = this.orderForm
			this.setTemp({
				firstName, lastName, phone, dob, zip
			})
		},
		toggleTerms() {
			this.agreeToTerms = !this.agreeToTerms
		}
	}
}
</script>
