<template>
	<div class="flex flex-wrap items-center justify-center w-full">
		<img
			class="w-48 h-48 mb-4"
			:src="imageSrc"
			alt="No results"
			width="192"
			height="192"
		>
		<h2 class="w-full text-lg font-bold text-center">
			{{ copy }}
		</h2>
	</div>
</template>

<script async>
export default {
	props: {
		copy: {
			type: String,
			required: true
		},
		imageSrc: {
			type: String,
			required: true
		}
	}
}
</script>
