const initialState = {}

const temp = {
	namespaced: true,
	state: initialState,
	mutations: {
		set: function (state, data) {
			state = Object.assign(state, data)
		}
	},
	actions: {
		setTemp: function (context, data) {
			context.commit('set', data)
		}
	}
}

export default temp
