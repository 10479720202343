<template>
	<div>
		<div class="flex flex-nowrap">
			<WwSwitch
				:id="'orderTerms'"
				@checked="$emit('input')"
			/>
			<div class="flex flex-wrap px-2 sm:flex-nowrap">
				<span class="pl-1">
					I agree to the
				</span>
				<span
					class="pl-1 text-green-500 cursor-pointer hover:text-purple-500"
					@click="showTerms = !showTerms"
				>
					terms of service
				</span>
			</div>
		</div>

		<div
			v-if="showTerms"
			class="px-4 py-2 mb-2 border rounded"
		>
			{{ orderingTerms }}
		</div>
	</div>
</template>

<script async>
import WwSwitch from '@/components/UI/WwSwitch.vue'
import orderingTerms from '@/etc/orderingTerms.js'

export default {
	components: {
		WwSwitch
	},
	props: {
		value: {
			type: Boolean,
			default: false
		}
	},
	emits: [ 'input' ],
	data() {
		return {
			showTerms: false,
			orderingTerms: orderingTerms
		}
	}
}
</script>
