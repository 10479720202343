export const dateFormat = function (date) { // accepts javascript date object and returns MM/DD/YYYY
	return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

export const formatTimeString = function (time) { // accepts string time hh:mm:ss and returns hh:mm AM/PM
	const hour = parseInt(time.split(':')[0])
	const minute = time.split(':')[1]
	let timeString = ''
	if (hour === 24 || hour === 0) {
		timeString = `12:${minute} AM`
	} else if (hour > 12) {
		timeString = `${hour - 12}:${minute} PM`
	} else {
		timeString = `${hour}:${minute} AM`
	}
	return timeString
}

export default { dateFormat, formatTimeString }
